<template>
  <div v-if="loaded">
    <div class="d-flex justify-space-between align-center mb-2 mt-6">
      <div class="input-wrap">
        <h6 v-if="cash && !last_opened">
          <span>
            {{ cash.cash.name }} -|- Monto Inicial:
            {{ cash.beginning_balance }} -|-
            <v-icon>mdi-calendar-today</v-icon> {{ date_opening }} -|-
            <v-icon>mdi-timer</v-icon> {{ cash.time_opening }}</span
          >
        </h6>
        <h6 v-if="!cash && last_opened">
          <span
            >{{ last_opened.cash.name }} -|- Monto Inicial:
            {{ last_opened.beginning_balance }} -|-
            <v-icon>mdi-calendar-today</v-icon> {{ date_opening }} -|-
            <v-icon>mdi-timer</v-icon> {{ last_opened.time_opening }}</span
          >
        </h6>

        <h6 v-if="!cash && !last_opened"><span>Apertura de caja</span></h6>
      </div>
      <div class="action-btn-wrap">
        <template v-if="cash && !last_opened">
          <v-btn
            color="secondary"
            class="mr-1"
            @click.prevent="openTrxDialog()"
          >
            <v-icon left>mdi-swap-horizontal</v-icon> Nuevo movimiento
          </v-btn>
          <v-btn color="accent" @click.prevent="clickCloseCash()">
            <v-icon left small>mdi-lock</v-icon> Cerrar caja
          </v-btn>
        </template>
        <template v-if="!cash && last_opened">
          <v-btn color="accent" @click.prevent="clickCloseCash()">
            <v-icon left small>mdi-lock</v-icon> Cerrar caja
          </v-btn>
        </template>
        <template v-if="!cash && !last_opened">
          <v-btn color="accent" @click.prevent="clickOpenCash()">
            <v-icon left small>mdi-lock-open</v-icon> Apertura de caja
          </v-btn>
        </template>
      </div>
    </div>
    <template>
      <v-card color="secondary" dark class="pa-3">
        <v-simple-table dark fixed-header dense height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">N°</th>
                <th class="text-center">FECHA Y HORA</th>
                <th class="text-center">OPERACIÓN</th>
                <th class="text-center">METODO P.</th>
                <th class="text-center">COMPROBANTE</th>
                <th class="text-center">NÚMERO</th>
                <th>REFERENCIA</th>
                <th class="text-right">INGRESO</th>
                <th class="text-right">EGRESO</th>
                <!-- <th class="text-right">SALDO</th> -->
                <th class="text-right">DETALLES</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in records" :key="index">
                <td scope="row" class="text-center">{{ index + 1 }}</td>
                <td scope="row" class="text-center">{{ row.created_at }}</td>
                <td scope="row" class="text-center">{{ row.operation }}</td>
                <td scope="row" class="text-center">
                  {{ row.payment_method_type }}
                </td>
                <td scope="row" class="text-center">{{ row.document_type }}</td>
                <td scope="row" class="text-center">{{ row.number }}</td>
                <td scope="row">{{ row.reference }}</td>
                <td class="text-right">
                  <span class="text-success">{{
                    row.type == "income" ? row.amount : ""
                  }}</span>
                </td>
                <td class="text-right">
                  <span class="text-danger">{{
                    row.type == "expense" ? row.amount : ""
                  }}</span>
                </td>
                <!-- <td class="text-right">
									<span>{{ row.balance }}</span>
								</td> -->
                <td class="text-right">
                  <v-menu offset-y v-if="row.items.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="my-1"
                        color="info"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        fab
                        x-small
                      >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                      </v-btn>
                    </template>
                    <v-card dark color="secondary">
                      <v-row class="mr-1 ml-1">
                        <v-col cols="12" xl="12" lg="12">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-right">
                                    Cantidad
                                  </th>
                                  <th class="text-left">
                                    Producto
                                  </th>
                                  <th class="text-right">
                                    Precio
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in row.items"
                                  :key="index"
                                >
                                  <td>
                                    {{ item.quantity }}
                                  </td>
                                  <td>
                                    {{ item.item.name }}
                                  </td>
                                  <td>
                                    {{ item.unit_price }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-row>
            <v-col
              cols="12"
              xl="12"
              v-for="(est, i) in items_by_establishment"
              :key="i"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td colspan="4" class="text-center">
                        <strong>{{ est.est.name }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-right">
                        N°
                      </th>
                      <th class="text-right">
                        Cant.
                      </th>
                      <th class="text-left">
                        Producto
                      </th>
                      <th class="text-right">
                        Importe
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in est.items" :key="index">
                      <td class="text-right">{{ index + 1 }}</td>
                      <td class="text-right">{{ item.quantity }}</td>
                      <td>{{ item.name }}</td>
                      <td class="text-right">S/ {{ item.total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    Pagos con Tarjeta:
                  </td>
                  <td class="text-right">S/ {{ totals.payment_card }}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    Pagos con Yape:
                  </td>
                  <td class="text-right">S/ {{ totals.payment_yape }}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    Pagos con Plin:
                  </td>
                  <td class="text-right">S/ {{ totals.payment_plin }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table dense class="mt-3">
            <template v-slot:default>
              <tbody>
                <!-- <tr>
					<td scope="row" class="text-right">Saldo Inicial:</td>
					<td class="text-right">
						{{
							cash
								? cash.beginning_balance
								: last_opened
								? last_opened.beginning_balance
								: 0
						}}
					</td>
				</tr> -->
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    <h6 class="success--text">
                      Total Ingresos:
                    </h6>
                  </td>
                  <td class="text-right">
                    <h6 class="success--text">S/ {{ totals.total_income }}</h6>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    <h6 class="error--text">
                      Total Egresos:
                    </h6>
                  </td>
                  <td class="text-right">
                    <h6 class="error--text">S/ {{ totals.total_expense }}</h6>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="text-right" width="75%">
                    <h5 class="success--text">
                      Saldo Final:
                    </h5>
                  </td>
                  <td class="text-right">
                    <h5 class="success--text">
                      S/
                      {{
                        totals.final_balance_frt ? totals.final_balance_frt : 0
                      }}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>

    <open-cash-form :showDialog.sync="showDialog"> </open-cash-form>

    <trx-form
      :showDialog.sync="showTrxFormDialog"
      :recordId="recordId"
      :finalBalance="finalBalance"
    >
    </trx-form>
  </div>
</template>

<script>
import { confirm } from "Mixins/confirm";
import OpenCashForm from "./Open";
import TrxForm from "./Trx";
import dayjs from "dayjs";

export default {
  mixins: [confirm],
  data() {
    return {
      showDialog: false,
      showTrxFormDialog: false,
      resource: "openings",
      recordId: null,
      records: [],
      payments: [],
      items_by_establishment: [],
      cash: null,
      last_opened: null,
      date_opening: null,
      totals: {
        total_income: "0.00",
        total_expense: "0.00",
        payment_card: "0.00",
        payment_plin: "0.00",
        payment_yape: "0.00",
      },
      finalBalance: 0,
      loaded: false,
    };
  },
  components: {
    OpenCashForm,
    TrxForm,
  },
  async created() {
    await this.$eventHub.$on("reloadData", (resource) => {
      // this.loaded = false
      if (this.resource == resource) {
        this.getOpenedCash();
      }
    });

    await this.getOpenedCash();
  },
  methods: {
    async getOpenedCash() {
      await this.$http.get(`${this.resource}/opened-cash`).then((response) => {
        if (response.data.success) {
          this.cash = response.data.cash;
          this.last_opened = response.data.last_opened;

          if (this.cash) {
            this.date_opening = dayjs(this.cash.date_opening).format(
              "DD/MM/YYYY"
            );
            this.getRecords();
          }

          if (this.last_opened) {
            this.date_opening = dayjs(this.last_opened.date_opening).format(
              "DD/MM/YYYY"
            );
            this.getRecords();
          }

          // this.getItems();
        }

        this.loaded = true;
      });
    },
    clickOpenCash() {
      this.showDialog = true;
    },
    clickCloseCash() {
      this.confirm().then(() => {
        let recordId = this.last_opened ? this.last_opened.id : this.cash.id;
        this.$http
          .post(`${this.resource}/close/${recordId}`)
          .then((response) => {
            if (response.data.success) {
              this.$message.success(response.data.message);
              location.reload();
              // this.$eventHub.$emit('reloadData', this.resource)
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            // console.log(error.response.data.message);
          });
      });
    },
    getRecords() {
      if (this.cash || this.last_opened) {
        let recordId = this.last_opened ? this.last_opened.id : this.cash.id;
        this.$http
          .get(`/${this.resource}/transactions/${recordId}`)
          .then((response) => {
            this.totals = response.data.totals;
            this.records = response.data.records;
          });
      }
    },
    getItems() {
      if (this.cash || this.last_opened) {
        let recordId = this.last_opened ? this.last_opened.id : this.cash.id;
        this.$http
          .get(`/${this.resource}/final-balance/${recordId}`)
          .then((resp) => {
            this.items_by_establishment = resp.data.data.items;
            console.log(this.items);
            // this.totals = response.data.totals;
            // this.records = response.data.records;
          });
      }
    },
    openTrxDialog() {
      this.recordId = this.cash.id;
      // let biginnig = this.cash ? this.cash.beginning_balance : this.last_opened ? this.last_opened.beginning_balance : 0;
      this.finalBalance = this.totals.final_balance;
      this.showTrxFormDialog = true;
    },
  },
};
</script>
