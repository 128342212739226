export const confirm = {
    methods: {
        confirm() {
            return new Promise((resolve) => {
                this.$alert('¿Desea realizar la operación?', 'Confirmar', {
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    // this.$message.success('Se eliminó correctamente el registro')
                    resolve()
                }).catch(error => {
                    console.log(error)
                });
            })
        }
    }
}